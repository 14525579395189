import { useGoogleAnalytics } from '@hooks';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ErrorPage, MainApp, LandingPage, PrivacyPolicy, TermsOfService, LoginPage } from '@pages';
import { useEffect } from 'react';
import { ExpressClient } from './http-clients';

export const EMPTY_ROUTE = '/#';
export const TERMS_OF_SERVICE_ROUTE = '/terms-of-service';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';
export const LOGIN_ROUTE = '/login';
export const LANDING_PAGE_ROUTE = '/';
export const MAIN_APP_ROUTE = '/app';
export const LANDING_DETAIL_PAGE_ROUTE = '/details';

export const SUPPORT_EMAIL = 'mailto:support@trufo.ai';
export const PRIVACY_CONTACT_EMAIL = 'mailto:privacy@trufo.ai';
export const CONTACT_US_EMAIL = 'mailto:contact@trufo.ai';
export const PARTNERSHIP_EMAIL = 'mailto:collabs@trufo.ai';
export const CAREERS_EMAIL = 'mailto:careers@trufo.ai';
export const PRESS_EMAIL = 'mailto:press@trufo.ai';

export const BLOG_LINK = 'https://medium.com/trufo';
export const DISCORD_LINK = 'https://discord.gg/BeZxSK5fFk';
export const X_LINK = 'https://twitter.com/TrufoAI';
export const INSTAGRAM_LINK = 'https://www.instagram.com/trufo.ai/';
export const CC_LICENSE_LINK = 'https://creativecommons.org/share-your-work/cclicenses/';
export const FEATURE_REQUEST_LINK = 'https://docs.google.com/forms/d/e/1FAIpQLSfGNMm6ComRA8C0KtNi-WFdV2dsHvM-e2UnD-IDeiU1GlDHWg/viewform?vc=0&c=0&w=1&flr=0';
export const AISIC_LINK = 'https://www.nist.gov/artificial-intelligence/artificial-intelligence-safety-institute';
export const CAI_LINK = 'https://contentauthenticity.org/';
export const C2PA_LINK = 'https://c2pa.org/';
export const VISION_LINK = 'https://medium.com/p/ae4afed08930';

export default function AppRoutes() {

  // useGoogleAnalytics();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <LandingPage />,
      errorElement: <ErrorPage />
    },
    {
      path: LANDING_PAGE_ROUTE,
      element: <LandingPage />,
      errorElement: <ErrorPage />
    },
    {
      path: LANDING_DETAIL_PAGE_ROUTE,
      element: <LandingPage page='details' />,
      errorElement: <ErrorPage />
    },
    {
      path: MAIN_APP_ROUTE,
      element: <MainApp />,
      errorElement: <ErrorPage />
    },
    {
      path: "/verify",
      element: <MainApp />,
      errorElement: <ErrorPage />
    },
    {
      path: LOGIN_ROUTE,
      element: <LoginPage />,
      errorElement: <ErrorPage />
    },
    {
      path: PRIVACY_POLICY_ROUTE,
      element: <PrivacyPolicy />,
      errorElement: <ErrorPage />
    },
    {
      path: TERMS_OF_SERVICE_ROUTE,
      element: <TermsOfService />,
      errorElement: <ErrorPage />
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}
