import { FontSizes, Fonts, TrufoCard } from "@/components";
import { Box, BoxProps } from "@mui/material";
import { ReactNode } from "react";

type LinkDetails = {
    title: string,
    description: string | ReactNode,
    url: string,
    compact?: boolean,
}

const links: LinkDetails[] = [
    {
        title: 'Trufo 101',
        description: "how do I use trufo?",
        url: 'https://medium.com/p/6c1b18882dfb',
    },
    {
        title: 'Our Vision',
        description: "why we're building what we're building",
        url: 'https://medium.com/p/ae4afed08930',
    },
    // {
    //     title: 'Encoding 101',
    //     description: "how to choose between encoding settings",
    //     url: '/#',
    // },
    // {
    //     title: 'Decoding 101',
    //     description: "how to understand the data stored in watermarks",
    //     url: '/#',
    // },
    {
        title: 'Why Watermark (Everything)?',
        description: "a cryptographer's perspective",
        url: 'https://medium.com/p/e7b9df714bf7',
    },
    {
        title: 'FAQs',
        description: 'a list of frequently asked questions',
        url: 'https://medium.com/p/a78e608e04f5',
    },
    {
        title: 'Benchmarking Watermarking',
        description: 'an open-source standard for evaluating modern watermarks',
        url: 'https://medium.com/p/436cbf1bd9ca',
    },
    {
        title: 'What Makes a Good Watermark?',
        description: 'durability, invisibility, reliability, scalability',
        url: 'https://medium.com/p/a0014138bf90',
        compact: true,
    },
    {
        title: "Evaluating Trufo's Watermarks",
        description: 'how good is it?',
        url: 'https://medium.com/p/af170eea759a',
        compact: true,
    },
]

interface ArticleLinksProps {
    containerProps?: BoxProps,
    tabIndex?: number,
}
export default function ArticleLinks({ containerProps, tabIndex }: ArticleLinksProps) {
    return (
        <Box
            display='flex' flexWrap='wrap' gap='20px'
            justifyContent='center' alignItems='start'
            {...containerProps}
        >
            {links.map(link =>
                <TrufoCard key={link.title} component='button' tabIndex={tabIndex || 0} onClick={() => window.open(link.url)}>
                    <Box
                        fontFamily={Fonts.monospace}
                        fontSize={FontSizes.header3}
                        marginBottom='5px'
                        maxWidth={link.compact ? '250px' : '450px'}
                    >
                        {link.title}
                    </Box>
                    <Box
                        fontFamily={Fonts.monospace}
                        maxWidth={link.compact ? '250px' : '450px'}
                    >
                        {link.description}
                    </Box>
                </TrufoCard>
            )}
        </Box>
    );
}