import { Colors, Fonts } from "@/components";
import { Box } from "@mui/material";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import SettingsSystemDaydreamOutlinedIcon from '@mui/icons-material/SettingsSystemDaydreamOutlined';

interface DetailsContentProps {
    variant: 'details' | 'texture',
    animSizePx: number,
    borderRadiusPx: number,
}

export default function DetailsContent({ variant, animSizePx, borderRadiusPx }: DetailsContentProps) {

    const fontSizePx = animSizePx / 25;
    const fontSizeDescriptionPx = animSizePx / 30;
    const fontSizeIconsPx = animSizePx / 12;

    return variant === 'details' ? (
        <Box
            height='100%' display='flex' flexDirection='column'
            alignItems='center' justifyContent='center'
            gap={`${animSizePx / 36}px`} fontFamily={Fonts.monospace} fontSize={fontSizePx}
            borderRadius={`${borderRadiusPx}px`}
        >
            <Box display='flex' flexDirection='column'>
                <Box><PersonOutlineIcon sx={{ fontSize: fontSizeIconsPx }} /></Box>
                <Box display='flex' gap={`${animSizePx / 40}px`} justifyContent='center' alignItems='center'>
                    Freepik <CheckCircleOutlinedIcon sx={{ fontSize: fontSizePx, color: 'green' }} />
                </Box>
            </Box>
            <Box display='flex' flexDirection='column'>
                <Box><ImageOutlinedIcon sx={{ fontSize: fontSizeIconsPx }} /></Box>
                <Box>Colorful Square</Box>
                <Box fontSize={`${fontSizeDescriptionPx}px`} color={Colors.black50}>
                    "A colorful 3d square with a green and orange background. Designed by Freepik."
                </Box>
            </Box>
            <Box display='flex' flexDirection='column'>
                <Box><SettingsSystemDaydreamOutlinedIcon sx={{ fontSize: fontSizeIconsPx }} /></Box>
                <Box>Watermark Date: Jan 31 2024</Box>
                <Box>License: Freepik (~CC.BY)</Box>
                <Box>Origination Claim: Human Created</Box>
            </Box>
            <Box display='flex' flexDirection='column'>
                <Box><ZoomInOutlinedIcon sx={{ fontSize: fontSizeIconsPx }} /></Box>
                <Box>Resized | Metadata dropped | No significant content alterations</Box>
            </Box>
        </Box>
    ) : (
        <Box
            color={Colors.black50} fontFamily={Fonts.monospace}
            fontSize={fontSizePx} borderRadius={`${borderRadiusPx}px`} width='100%' height='100%'
            sx={{ overflowWrap: 'break-word', overflow: 'hidden' }}
        >
            1010010101001011101010101101010100101011010010101001011101010101101010100101011010010101001011101010101101010100101011010010101001011101010101101010100101011010011010010111010101011010101001010110101101010100101011010011010100101110101010110101010010101101001010100101110101010110101010010101101001010100101110101010110101010010101101001010100101110101010101010110100101010010111010101011010101001010110100101110101010010101101001010100101110101010101010110100101010010111010101011010101001000101110101010110101010010101101001010100101110101010110101010010101101001010100101110101110101010110101010010101101001010101001010100101110101010101010101011010101001010110101011101010101101010111101010101101010100101011010010111010101001010110100101010010111010101010101011010010101001011101010101101010100100010111010101011001010010111010101011010101001010110100101010101010100101011010011011010100101110101010110101010010101101001010100101110101010110101010010101101001
        </Box>
    )
}