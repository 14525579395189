import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppRoutes from './app-routes';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider, createTheme } from '@mui/material';
import HttpsRedirect from 'react-https-redirect';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const muiTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "'Jost', sans-serif",
        },
      },
    },
  },
});

// TODO: replace HttpsRedirect with server-side redirect

root.render(
  <HttpsRedirect>
    <GoogleOAuthProvider clientId="1078781897544-ftaqs1qok8cpltattel2pgvqcv3k91bj.apps.googleusercontent.com">
      <React.StrictMode>
        <ThemeProvider theme={muiTheme}>
          <AppRoutes />
        </ThemeProvider>
      </React.StrictMode>
    </GoogleOAuthProvider>
  </HttpsRedirect>
);
