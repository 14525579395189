import { TextField, TextFieldProps } from "@mui/material"
import { Colors, FontSizes, Fonts, ZIndexes } from "./variables";

type TrufoTextFieldColorVariant = 'light' | 'dark' | 'accentLight' | 'accentDark' | 'black70';
type TrufoTextFieldVariant = 'outlined' | 'filled';

type TrufoTextFieldProps = TextFieldProps & {
    colorVariant?: TrufoTextFieldColorVariant;
    trufoVariant?: TrufoTextFieldVariant;
    font?: string;
    onValueChange?: (newVal: string) => void;
}


export default function TrufoTextField({ colorVariant, trufoVariant, font, onValueChange, ...muiProps }: TrufoTextFieldProps) {
    if (!colorVariant) colorVariant = 'accentDark';
    if (!trufoVariant) trufoVariant = 'filled';

    const variantStyles: Record<TrufoTextFieldVariant, any> = {
        filled: {
            '& input, textarea': {
                fontSize: FontSizes.body2,
                fontFamily: font || Fonts.trufo,
                padding: '10px',
            },
            '& .MuiInputBase-root': {
                padding: '0px',
            },
            '& .MuiInputBase-root:after': {
                borderBottomColor: Colors[colorVariant],
            },
        },
        outlined: {
            '& input, textarea': {
                fontFamily: font || Fonts.monospace,
                color: Colors.black70,
            },
            '& label.Mui-focused': {
                color: Colors[colorVariant],
                fontFamily: font || Fonts.monospace,
            },
            '& label': {
                color: Colors.black70,
                fontFamily: font || Fonts.monospace,
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: Colors.black70,
                },
                '&:hover fieldset': {
                    borderColor: Colors[colorVariant],
                },
                '&.Mui-focused fieldset': {
                    borderColor: Colors[colorVariant],
                },
            },
        }
    }

    return (
        <TextField
            onChange={(e) => onValueChange && onValueChange(e?.target?.value)}
            variant={trufoVariant}
            {...muiProps}
            sx={{
                zIndex: ZIndexes.clickable,
                width: '100%',
                ...variantStyles[trufoVariant],
                ...muiProps?.sx,
            }}
        />
    );
}