import { AppBar, Box, Toolbar, Container, Button, Menu, IconButton, Avatar, Alert } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import trufo_white from '@assets/trufo_white.png';
import trufo_black from '@assets/trufo_black.png';
import { getGoogleUserJwtFromStorage, getGoogleUserLoginFromJwt, logInGoogleUser, logOutGoogleUser } from '@utils';
import { Colors, ZIndexes } from './variables';
import { GoogleLogin } from '@react-oauth/google';
import { CompactMenu, TrufoMenuItem } from '.';
import { useGetUser, useSettingsModal } from '@hooks';
import { jwtDecode } from 'jwt-decode';
import { JwtPayloadGoogle } from '@/types';
import { BLOG_LINK, DISCORD_LINK, LANDING_PAGE_ROUTE, LOGIN_ROUTE, MAIN_APP_ROUTE, SUPPORT_EMAIL } from '@/app-routes';
import { SETTINGS_COMPACT_VIEW_WIDTH_PX, NAVBAR_COMPACT_VIEW_WIDTH_PX } from '@/pages/main-app/constants';

export const NAVBAR_HEIGHT_PX = 72;

export type NavbarProps = {
  theme: 'light' | 'dark';
  zIndex?: number;
  onAccountSettingsSave?: () => void;
}

export default function Navbar({ theme, zIndex, onAccountSettingsSave }: NavbarProps) {

  const links: Record<string, string> = {
    home: MAIN_APP_ROUTE,
    blog: BLOG_LINK,
    discord: DISCORD_LINK,
    support: SUPPORT_EMAIL,
  }

  const { SettingsModal, openSettingsModal } = useSettingsModal();

  // const { loginUser: trufoLogin, data: trufoLoginData, loading: trufoLoginLoading, error: trufoLoginError } = useLoginUser();
  const { getUser: getTrufoUser, data: trufoUserData, loading: getTrufoUserDataLoading, error: getTrufoUserError } = useGetUser();
  const trufoUserValid = trufoUserData && !getTrufoUserError;

  const [googleUserJwt, setGoogleUserJwt] = useState<JwtPayloadGoogle | undefined>(getGoogleUserJwtFromStorage() || undefined);
  const googleUserData = googleUserJwt && getGoogleUserLoginFromJwt(googleUserJwt);
  const [googleLoginError, setGoogleLoginError] = useState<boolean>(false);
  const [googleLoginDirty, setGoogleLoginDirty] = useState<boolean>(false);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const [settingCompactView, setSettingsCompactView] = useState<boolean>(false);
  const [navbarCompactView, setNavbarCompactView] = useState<boolean>(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  useEffect(() => {
    // if signed in thru google + successfully retrieved trufo user under google user, reload page
    if (googleLoginDirty && trufoUserValid) window.location.reload();
    // if no trufo user, take to login page for access code flow
    if (googleLoginDirty && getTrufoUserError) window.location.href = LOGIN_ROUTE;
  }, [trufoUserData, getTrufoUserError, googleLoginDirty, trufoUserValid])

  // whenever google jwt changes, re-fetch trufo user
  useEffect(() => { googleUserData && getTrufoUser(googleUserData) }, [googleUserJwt])

  // track app width for reactive layouts
  const navbarContainer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!navbarContainer.current) return;
    const resizeObserver = new ResizeObserver((elementArr) => {
      const width = elementArr?.[0]?.contentRect?.width;
      if (width && width < SETTINGS_COMPACT_VIEW_WIDTH_PX) {
        setSettingsCompactView(true);
      } else {
        setSettingsCompactView(false);
      }
      if (width && width < NAVBAR_COMPACT_VIEW_WIDTH_PX) {
        setNavbarCompactView(true);
      } else {
        setNavbarCompactView(false);
      }
    });
    resizeObserver.observe(navbarContainer.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, []);

  // TODO: support compact view for navbar

  const Logo =
    <Box
      component='img' alt='trufo' src={theme === 'light' ? trufo_black : trufo_white}
      sx={{
        height: '50px', cursor: 'pointer',
        marginRight: theme === 'light' ? 1 : 3,
        marginLeft: theme === 'light' ? '-16px' : '0px'
      }}
      onClick={() => window.location.href = LANDING_PAGE_ROUTE}
    />;

  const NonCompactLinks =
    <Box sx={{ flexGrow: 1, gap: 0, display: 'flex' }}>
      {Object.keys(links).map((linkName) => (
        <Button
          key={linkName}
          href={links[linkName]}
          sx={{
            my: 2, display: 'block', textTransform: 'none', fontSize: '16px',
            color: theme === 'light' ? Colors.dark : Colors.white,
          }}
        >
          {linkName}
        </Button>
      ))}
    </Box>;

  const CompactMenuForLinks = <CompactMenu links={links} />;

  return (
    <>
      <AppBar
        ref={navbarContainer}
        sx={{
          position: 'relative',
          backgroundColor: theme === 'light' ? Colors.light : Colors.dark,
          height: `${NAVBAR_HEIGHT_PX}px`,
          display: 'flex', justifyContent: 'center',
          zIndex: zIndex || ZIndexes.navbar,
        }}
      >
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <Box
              display='flex' justifyContent='space-between' width='100%' alignItems='center'
              color={theme === 'light' ? Colors.dark : Colors.white}
            >

              {/* left side: logo & nav items for non-compact || logo for compact */}
              <Box display='flex' alignItems='center'>
                {Logo}
                {!navbarCompactView && NonCompactLinks}
              </Box>

              {/* right side: user button for non-compact || user button & compact nav menu for compact */}
              <Box display='flex' alignItems='center'>
                {/* <Box>v 0.1</Box> */}
                {navbarCompactView && CompactMenuForLinks}
                <Box>
                  <IconButton
                    size="small"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    color="inherit"
                  >
                    <Avatar
                      sx={{
                        width: 32, height: 32,
                        backgroundColor: theme === 'light' ? Colors.dark : Colors.gray,
                        '&:hover': { opacity: 0.8 }
                      }}
                    >
                      {googleUserJwt?.name?.charAt(0) || null}
                    </Avatar>
                  </IconButton>
                  <Menu
                    open={Boolean(menuAnchorEl)}
                    anchorEl={menuAnchorEl}
                    onClose={handleMenuClose}
                  >
                    {trufoUserValid ?
                      (<Box>
                        <TrufoMenuItem
                          onClick={() => {
                            handleMenuClose();
                            openSettingsModal();
                          }}
                        >
                          Account Settings
                        </TrufoMenuItem>
                        <TrufoMenuItem onClick={() => { logOutGoogleUser(); handleMenuClose(); }}>
                          Log Out
                        </TrufoMenuItem>
                      </Box>)
                      :
                      (<TrufoMenuItem>
                        <GoogleLogin
                          onSuccess={(credentialResponse) => {
                            // google login
                            const jwt = credentialResponse?.credential;
                            if (jwt) {
                              const jwtObj = jwtDecode(jwt) as JwtPayloadGoogle;
                              const googleUserLogin = getGoogleUserLoginFromJwt(jwtObj);
                              if (googleUserLogin) {
                                setGoogleLoginError(false);
                                logInGoogleUser(jwt);
                                setGoogleUserJwt(jwtObj);
                                setGoogleLoginDirty(true);
                              } else {
                                setGoogleLoginError(true);
                              }
                            } else {
                              setGoogleLoginError(true);
                            }
                          }}
                          onError={() => setGoogleLoginError(false)}
                          theme='filled_black'
                          shape='pill'
                        />
                      </TrufoMenuItem>)
                    }
                  </Menu>
                  {trufoUserValid && <SettingsModal compact={settingCompactView} onSuccessfulSave={onAccountSettingsSave} />}
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {googleLoginError && <Box
        position='absolute' left='0px' bottom='0px'
        width='100dvw' zIndex={ZIndexes.alerts}
      >
        <Alert severity="error">Google Login Error</Alert>
      </Box>}
    </>
  );
}