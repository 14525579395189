import { Colors, FontSizes, Fonts, TrufoButton, TrufoTextField, horizontallyCenteredAbsoluteStyle } from "@/components";
import { useJoinNewsletter } from "@/hooks";
import { Box, BoxProps, CircularProgress } from "@mui/material";
import { useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface NewsletterSignupProps {
    containerProps?: BoxProps,
    tabIndex?: number,
}
export default function NewsletterSignup({ containerProps, tabIndex }: NewsletterSignupProps) {

    const [email, setEmail] = useState<string>('');
    const [dirty, setDirty] = useState<boolean>(false);
    const { joinNewsletter, data, loading, error } = useJoinNewsletter();

    const buttonTextStyle = { opacity: loading ? 0 : 1 };
    const onlyWhitespace = (str: string) => str.trim().length === 0;

    return (
        <Box
            fontFamily={Fonts.monospace}
            {...containerProps}
        >
            <Box
                fontSize={FontSizes.body1} marginBottom='20px' color={Colors.black70}
                textAlign='center'
            >
                Be the first to hear about Trufo updates!
            </Box>
            <form
                onSubmit={(e) => { e.preventDefault(); setDirty(false); if (email) joinNewsletter(email); }}
                style={{ display: 'flex', gap: '10px' }}
            >
                <TrufoTextField
                    colorVariant='light' trufoVariant='outlined'
                    type='email' label='email' value={email}
                    onValueChange={(newVal: string) => { setEmail(newVal); setDirty(true); }}
                    inputProps={{ tabIndex: tabIndex || 0 }}
                />
                <TrufoButton
                    type='submit' disabled={Boolean(!email || onlyWhitespace(email)) || Boolean(!dirty && data)}
                    sx={{
                        fontFamily: Fonts.monospace,
                        borderRadius: '4px',
                        ...((!dirty && (data)) ? { color: `${Colors.green70} !important` } : {})
                    }}
                    tabIndex={tabIndex || 0}
                >
                    {loading && <Box sx={horizontallyCenteredAbsoluteStyle}><CircularProgress size={FontSizes.header2} sx={{ color: Colors.black70 }} /></Box>}
                    {((data?.found && !dirty) ?
                        <Box display='flex' justifyContent='center' alignItems='center' gap='5px' sx={buttonTextStyle}><CheckCircleIcon />already signed up</Box>
                        : (data && !dirty) ?
                            <Box display='flex' justifyContent='center' alignItems='center' gap='5px' sx={buttonTextStyle}><CheckCircleIcon />signed up!</Box>
                            : <Box sx={buttonTextStyle}>sign up</Box>)
                    }
                </TrufoButton>
            </form>
        </Box >
    );
}