import { Box, Button, ButtonProps, CircularProgress } from "@mui/material";
import { Colors, ZIndexes } from "./variables";

type TrufoButtonColorVariant = 'light' | 'white' | 'dark' | 'accentLight' | 'accentDark';

type TrufoButtonProps = ButtonProps & {
    label?: string | JSX.Element;
    loading?: boolean;
    disabled?: boolean;
    fontSize?: number;
    colorVariant?: TrufoButtonColorVariant;
}

type TrufoButtonColor = {
    fontColor: string,
    buttonColor: string,
    buttonOpacity?: number,
    buttonColorActive?: string,
    buttonOpacityActive?: number,
    buttonColorHover?: string,
    buttonOpacityHover?: number,
    buttonColorDisabled: string,
}

export default function TrufoButton({ label, loading, disabled, fontSize, colorVariant, children, ...muiProps }: React.PropsWithChildren<TrufoButtonProps>) {

    if (!colorVariant) { colorVariant = 'light'; }

    const buttonColors: Record<TrufoButtonColorVariant, TrufoButtonColor> = {
        white: {
            buttonColor: Colors.offWhite,
            fontColor: Colors.dark,
            buttonOpacity: 0.7,
            buttonColorActive: Colors.blueDefault, // TODO: change this
            buttonOpacityHover: 1,
            buttonColorDisabled: Colors.lightGray,
        },
        light: {
            buttonColor: Colors.light,
            fontColor: Colors.dark,
            buttonOpacity: 0.7,
            buttonColorActive: Colors.blueDefault, // TODO: change this
            buttonOpacityHover: 1,
            buttonColorDisabled: Colors.lightGray,
        },
        dark: {
            buttonColor: Colors.dark,
            fontColor: Colors.white,
            buttonColorActive: '#242582',
            // buttonColorHover: '#32323d',
            buttonOpacity: 0.9,
            buttonOpacityHover: 1,
            buttonColorDisabled: Colors.lightGray,
        },
        accentLight: {
            buttonColor: Colors.accentLight,
            fontColor: Colors.black,
            buttonOpacityHover: 0.8,
            buttonColorDisabled: Colors.lightGray,
        },
        accentDark: {
            buttonColor: Colors.accentDark,
            fontColor: Colors.white,
            buttonOpacityHover: 0.8,
            buttonColorDisabled: Colors.lightGray,
        },
    };

    return (
        <Button
            disabled={disabled}
            {...muiProps}
            sx={{
                zIndex: ZIndexes.clickable,
                alignItems: 'center',
                appearance: 'button',
                backgroundColor: buttonColors[colorVariant].buttonColor,
                color: buttonColors[colorVariant].fontColor,
                opacity: buttonColors[colorVariant].buttonOpacity || 1,
                borderRadius: '8px',
                borderStyle: 'none',
                boxShadow: 'rgba(255, 255, 255, 0.26) 0 1px 2px inset',
                boxSizing: 'border-box',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                flexShrink: 0,
                fontSize: '100%',
                lineHeight: 1.15,
                margin: 0,
                padding: '6px 12px',
                textALign: 'center',
                transition: 'color .13s ease-in-out,background .13s ease-in-out,opacity .13s ease-in-out,box-shadow .13s ease-in-out',
                userSelect: 'none',
                WebkitUserSelect: 'none',
                textTransform: 'none',
                touchAction: 'manipulation',
                '&:active': {
                    backgroundColor: buttonColors[colorVariant].buttonColorActive || buttonColors[colorVariant].buttonColor,
                    opacity: buttonColors[colorVariant].buttonOpacityActive || 1,
                },
                '&:hover': {
                    backgroundColor: buttonColors[colorVariant].buttonColorHover || buttonColors[colorVariant].buttonColor,
                    opacity: buttonColors[colorVariant].buttonOpacityHover || 1,
                },
                '&:disabled': {
                    backgroundColor: buttonColors[colorVariant].buttonColorDisabled,
                },
                ...muiProps?.sx,
            }}
        >
            <Box visibility={loading ? 'visible' : 'hidden'} position='absolute' width='100%' textAlign='center'>
                <CircularProgress size={`${fontSize ? fontSize + 4 : 18}px`} sx={{ color: buttonColors[colorVariant].fontColor }} />
            </Box>
            {children ?
                <Box visibility={loading ? 'hidden' : 'visible'}>{children}</Box>
                :
                <Box visibility={loading ? 'hidden' : 'visible'} fontSize={`${fontSize || 14}px`}>{label}</Box>
            }
        </Button >
    )
}