export type AnimStage = 'topFocus' | 'topHover' | 'initial' | 'bottomMerged' | 'bottomFocus';

export const MIN_ANIM_SIZE_PX = 160;
export const MAX_ANIM_SIZE_PX = 1000;

export const getResponsiveComponentPositions = (animSizePx: number) => {
    const m = animSizePx / 300;
    const ret = {
        top: {
            t: { initial: -50 * m, hover: -150 * m, merged: 100 * m, bottomFocus: 100 * m, centered: 0 },
        },
        bottom: {
            t: { initial: 50 * m, exitX: 1400 * m, exitY: 2000 * m, merged: 100 * m, focus: 100 * m, centered: 0 },
        },
        parent: {
            rotate: { X: 51, Z: 43 },
        },
    }
    return ret;
};
export const SCROLL_POSITIONS: Record<AnimStage, number> = {
    topFocus: 0,
    topHover: 0.25,
    initial: 0.5,
    bottomMerged: 0.75,
    bottomFocus: 1,
};

export const RIGHT_COL_MIN_WIDTH_PX = 300;
export const COMPACT_VIEW_WIDTH_PX = 720;
export const EXTRA_COMPACT_VIEW_WIDTH_PX = 420;