import { Select, SelectProps } from "@mui/material";
import { Colors, FontSizes, Fonts, ZIndexes } from "./variables";

type TrufoDropdownColorVariant = 'accentDark';

type TrufoDropdownProps = SelectProps & {
    colorVariant?: TrufoDropdownColorVariant
}

export default function TrufoCheckbox({ colorVariant, children, ...muiProps }: React.PropsWithChildren<TrufoDropdownProps>) {

    const variantStyles = {
        accentDark: {
            borderColor: Colors.accentDark50,
            borderColorHover: Colors.accentDark,
            borderColorFocus: Colors.accentDark,
            backgroundColorHover: Colors.accentDark05,
            textColor: Colors.accentDark,
            iconColor: Colors.accentDark,
            menuBackgroundColor: Colors.offWhite,
            menuItemColorHover: Colors.black10,
            menuItemColorSelected: Colors.accentDark10,
        },
    }

    if (!colorVariant) { colorVariant = 'accentDark' };

    const style = variantStyles[colorVariant];

    return (
        <Select
            {...muiProps}
            sx={{
                zIndex: ZIndexes.clickable,
                borderRadius: 1,
                fontFamily: Fonts.trufo,
                fontSize: FontSizes.body2,
                '& .MuiInputBase-input': {
                    padding: '8px 30px 8px 12px',
                    color: style.textColor,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${style.borderColor}`
                },
                '&:focus-within': {
                    '&& fieldset': {
                        // borderColor: style.borderColorFocus,
                        border: `1px solid ${style.borderColor}`,
                    }
                },
                ...(!muiProps?.disabled ? {
                    '&:hover': {
                        '&& fieldset': {
                            borderColor: style.borderColorHover,
                            backgroundColor: style.backgroundColorHover,
                        }
                    },
                    '& .MuiSelect-icon': {
                        color: style.iconColor,
                    },
                } : {}),
                ...muiProps?.sx,
            }}
            MenuProps={{
                slotProps: {
                    paper: {
                        sx: {
                            backgroundColor: style.menuBackgroundColor,
                            '& .MuiMenuItem-root': {
                                fontFamily: Fonts.trufo,
                                fontSize: FontSizes.body2,
                            },
                            '& .MuiMenuItem-root:hover, & .MuiMenuItem-root.Mui-selected:hover': {
                                backgroundColor: style.menuItemColorHover,
                            },
                            '& .MuiMenuItem-root.Mui-selected': {
                                backgroundColor: style.menuItemColorSelected,
                            },
                        },
                    },
                },
                ...muiProps?.MenuProps,
            }}
        >
            {children}
        </Select>
    );
}