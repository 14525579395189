import { AISIC_LINK, C2PA_LINK, CAI_LINK, CAREERS_EMAIL, CONTACT_US_EMAIL, PARTNERSHIP_EMAIL, PRESS_EMAIL } from "@/app-routes";
import { FontSizes, Fonts, TrufoLink } from "@/components";
import { Box, BoxProps } from "@mui/material";

type AboutTrufoProps = {
    tabIndex?: number,
    containerProps?: BoxProps,
}

export default function AboutTrufo({ tabIndex, containerProps }: AboutTrufoProps) {
    return (
        <Box
            display='flex' flexDirection='column' gap='16px'
            fontFamily={Fonts.monospace} fontSize={FontSizes.body1} lineHeight='130%'
            {...containerProps}
        >
            <Box>We are a small team of researchers and engineers from Princeton / Stanford / UChicago and Google / Netflix / Jane Street. We believe that developments in generative AI, while exciting and beneficial in other realms, have the potential to fuel misinformation at an unprecedented scale. We aim to build the best long-term solution to this problem.</Box>
            <Box>We are an inaugural member of the <TrufoLink href={AISIC_LINK} newtab tabIndex={tabIndex || 0}>U.S. AISIC</TrufoLink> and an active member of the <TrufoLink href={CAI_LINK} newtab tabIndex={tabIndex || 0}>CAI</TrufoLink> and <TrufoLink href={C2PA_LINK} newtab tabIndex={tabIndex || 0}>C2PA</TrufoLink>.</Box>
            <Box>If you'd like to join the team, and know how you can help, email us at <TrufoLink href={CAREERS_EMAIL} tabIndex={tabIndex || 0}>careers@trufo.ai</TrufoLink>.</Box>
            <Box>If your organization is interested in collaborating with Trufo, email us at <TrufoLink href={PARTNERSHIP_EMAIL} tabIndex={tabIndex || 0}>collabs@trufo.ai</TrufoLink>.</Box>
            <Box>For press inquiries email <TrufoLink href={PRESS_EMAIL} tabIndex={tabIndex || 0}>press@trufo.ai</TrufoLink>, and for all other inquiries email us at <TrufoLink href={CONTACT_US_EMAIL} tabIndex={tabIndex || 0}>contact@trufo.ai</TrufoLink>.</Box>
        </Box>
    );
}