import { Link, LinkProps } from "@mui/material";
import { Colors } from ".";

type TrufoLinkVariants = 'black' | 'accentDark' | 'white';
type TrufoLinkProps = LinkProps & {
    trufoVariant?: TrufoLinkVariants;
    newtab?: boolean;
};

export default function TrufoLink({ newtab, trufoVariant, href, children, ...muiProps }: React.PropsWithChildren<TrufoLinkProps>) {
    if (!trufoVariant) trufoVariant = 'black';

    const variantStyles = {
        black: {
            color: Colors.black, textDecorationColor: Colors.black,
            '&:hover': { color: Colors.accentDark, textDecorationColor: Colors.accentDark }
        },
        accentDark: {
            color: Colors.accentDark, textDecorationColor: Colors.accentDark,
            '&:hover': { color: Colors.accentLight, textDecorationColor: Colors.accentLight }
        },
        white: {
            color: Colors.white, textDecorationColor: Colors.white,
            '&:hover': { color: Colors.accentLight, textDecorationColor: Colors.accentLight }
        }
    }

    return (
        <Link
            href={href}
            {...(!href && { component: 'button' })}
            {...(newtab && { target: '_blank' })}
            {...muiProps}
            sx={{ cursor: 'pointer', textAlign: 'center', ...variantStyles[trufoVariant], ...muiProps.sx }}
        >
            {children}
        </Link>
    )
}
