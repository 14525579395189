import { PRIVACY_POLICY_ROUTE, TERMS_OF_SERVICE_ROUTE } from "@/app-routes";
import { Colors, FontSizes, TrufoLink } from "@/components";
import { Box } from "@mui/material";

interface FooterProps {
    tabIndex?: number,
    maxWidth?: string,
}
export default function Footer({ tabIndex, maxWidth }: FooterProps) {
    return (
        <Box
            padding='20px' width='calc(100% - 40px)'
            sx={{ borderTop: `1px solid ${Colors.black70}` }}
            fontSize={FontSizes.body1} color={Colors.black70}
        >
            <Box
                display='flex' alignItems='center' justifyContent='space-between' maxWidth={maxWidth}
                marginLeft='auto' marginRight='auto'
            >
                <TrufoLink tabIndex={tabIndex || 0} sx={{ color: Colors.black70 }} href={TERMS_OF_SERVICE_ROUTE}>Terms of Service</TrufoLink>
                <Box textAlign='center'>Trufo © 2024. All rights reserved.</Box>
                <TrufoLink tabIndex={tabIndex || 0} sx={{ color: Colors.black70 }} href={PRIVACY_POLICY_ROUTE}>Privacy Policy</TrufoLink>
            </Box>
        </Box>
    );
}