import './styles.css';
import { useScroll } from 'framer-motion';
import { useRef, useState } from 'react';
import AnimText from './anim-text';
import Anim from './anim';
import AnimScroll from './anim-scroll';

interface WatermarkAnimProps {
    animSizePx: number;
    tabIndex?: number;
}
export default function WatermarkAnim({ animSizePx, tabIndex }: WatermarkAnimProps) {

    const scrollContainer = useRef<HTMLElement>(null);
    const [initialAnimationComplete, setInitialAnimationComplete] = useState<boolean>(false);
    const { scrollYProgress } = useScroll({ container: scrollContainer });

    return (
        <>
            {/* scroll support */}
            <AnimScroll
                scrollYProgress={scrollYProgress}
                initialAnimationComplete={initialAnimationComplete}
                scrollContainer={scrollContainer}
                tabIndex={tabIndex}
            />

            {/* main animation */}
            <Anim
                scrollYProgress={scrollYProgress}
                initialAnimationComplete={initialAnimationComplete}
                setInitialAnimationComplete={() => setInitialAnimationComplete(true)}
                animSizePx={animSizePx}
            />

            {/* animation text */}
            <AnimText
                animSizePx={animSizePx}
                scrollYProgress={scrollYProgress}
                initialAnimationComplete={initialAnimationComplete}
            />
        </>
    );
}
