import { BoxProps, Box } from "@mui/material";
import { Colors } from ".";

type GradientBoxPositionVariants = 'top';
type GradientBoxColorVariants = 'dark' | 'white';
type GradientBoxProps = BoxProps & {
    positionVariant?: GradientBoxPositionVariants,
    colorVariant?: GradientBoxColorVariants,
};

export default function GradientBox({ positionVariant, colorVariant, ...muiProps }: GradientBoxProps) {
    if (!positionVariant) positionVariant = 'top';
    if (!colorVariant) colorVariant = 'white';

    const variantStyles = {
        dark: {
            colorStart: Colors.dark100,
            colorEnd: Colors.dark0,
        },
        white: {
            colorStart: Colors.white100,
            colorEnd: Colors.white0,
        }
    }

    return (
        <Box
            position='absolute' left='0px' top='0px'
            width='100%' height='100px'
            {...muiProps}
            sx={{ background: `linear-gradient(to bottom, ${variantStyles[colorVariant].colorStart} 0%, ${variantStyles[colorVariant].colorEnd} 100%)`, ...muiProps.sx }}
        />
    )
}
