import { Box } from '@mui/material';
import { motion, useTransform, easeInOut, MotionValue } from 'framer-motion';
import { AnimStage, SCROLL_POSITIONS, getResponsiveComponentPositions } from "../types-and-constants";
import { Colors } from '@components';

type AnimTextProps = {
    scrollYProgress: MotionValue<number>,
    initialAnimationComplete: boolean,
    animSizePx: number,
}

export default function AnimText({ scrollYProgress, initialAnimationComplete, animSizePx }: AnimTextProps) {

    const c = getResponsiveComponentPositions(animSizePx);
    const s = SCROLL_POSITIONS;

    const useTextAnimStyle = (stage: AnimStage) => ({
        opacity: useTransform(scrollYProgress, [s[stage] - 0.1, s[stage], s[stage] + 0.1],
            [0, 1, 0],
            { ease: easeInOut }
        ),
        width: '100%', height: '100%',
    })
    const initialTextStyle = useTextAnimStyle('initial');
    const topTextStyle = useTextAnimStyle('topFocus');
    const fontSize = animSizePx / 13;
    const textStyle = { position: 'absolute', width: '100%', textAlign: 'center', fontSize: fontSize };

    return (
        <Box sx={{ position: 'absolute', left: 0, top: 0, height: '100dvh', width: '100%', color: Colors.white }}>
            <motion.div style={initialAnimationComplete ? topTextStyle : { opacity: 0 }} className='position-absolute'>
                {/* <Box sx={{ top: `calc(50% + ${-animSizePx / 2 + c.top.t.hover - 30}px)`, ...textStyle }}>
                    placeholder text for 0 top
                </Box> */}
                {/* <Box sx={{ top: `calc(50% + ${animSizePx / 2 + c.top.t.hover + 10}px)`, ...textStyle }}>
                    placeholder text for 0 bottom
                </Box> */}
                <Box sx={{ top: `calc(50% + ${c.top.t.hover + animSizePx / 2 + fontSize - animSizePx / 30}px)`, ...textStyle }}>
                    authenticate the content you see
                </Box>
            </motion.div>

            <motion.div style={useTextAnimStyle('topHover')} className='position-absolute'>
                {/* <Box sx={{ top: `calc(50% + ${animSizePx / 2 + 40}px)`, ...textStyle }}>
                    placeholder text for 0.25
                </Box> */}
                {/* <Box sx={{ top: `calc(50% + ${animSizePx / 2 + 40}px)`, ...textStyle }}>
                    ↑ decode cryptographically
                </Box> */}
            </motion.div>

            <motion.div style={initialAnimationComplete ? initialTextStyle : { opacity: 0 }} className='position-absolute'>
                {/* <Box sx={{ top: `calc(50% + ${animSizePx / 2 + 40}px)`, ...textStyle }}>
                    placeholder text for 0.5
                </Box> */}
                <Box sx={{ top: `calc(50% + ${c.bottom.t.initial + animSizePx / 2 + fontSize - animSizePx / 8}px)`, ...textStyle }}>
                    embed trust in digital media
                </Box>
            </motion.div>

            <motion.div style={useTextAnimStyle('bottomMerged')} className='position-absolute'>
                {/* <Box sx={{ top: `calc(50% + ${animSizePx / 2 + 30 + 50}px)`, ...textStyle }}>
                    placeholder text for 0.75
                </Box> */}
                {/* <Box sx={{ top: `calc(50% + ${animSizePx / 2 + 30 + 50}px)`, ...textStyle }}>
                    ↓ encode cryptographically
                </Box> */}
            </motion.div>

            <motion.div style={useTextAnimStyle('bottomFocus')} className='position-absolute'>
                {/* <Box sx={{ top: `calc(50% + ${-animSizePx / 2 + c.bottom.t.focus - 40}px)`, ...textStyle }}>
                   placeholder text for 1 top
                </Box> */}
                {/* <Box sx={{ top: `calc(50% + ${animSizePx / 2 + c.bottom.t.focus + 10}px)`, ...textStyle }}>
                    placeholder text for 1 bottom
                </Box> */}
                <Box sx={{ top: `calc(50% + ${c.bottom.t.focus - animSizePx / 2 - fontSize - animSizePx / 12}px)`, ...textStyle }}>
                    choose how to mark your content
                </Box>
            </motion.div>
        </Box>
    );
}