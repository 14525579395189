import { Box } from '@mui/material';
import { motion, useTransform, easeInOut, MotionValue } from 'framer-motion';
import image_square_2 from '@assets/image_square_2.jpg';
import { useMemo } from 'react';
import { SCROLL_POSITIONS, getResponsiveComponentPositions } from '../types-and-constants';
import DetailsContent from './details-content';
import DetailsWatermark from './details-watermark';
import { ZIndexes } from '@/components';

type AnimProps = {
    scrollYProgress: MotionValue<number>,
    initialAnimationComplete: boolean,
    setInitialAnimationComplete: () => void,
    animSizePx: number,
}

export default function Anim({ scrollYProgress, initialAnimationComplete, setInitialAnimationComplete, animSizePx }: AnimProps) {

    const c = getResponsiveComponentPositions(animSizePx);
    const s = SCROLL_POSITIONS;

    const borderRadiusPx = animSizePx / 8;

    const initialAnimations = useMemo(() => ({
        parent: {
            animate: { rotateX: [0, 0, 51], rotateY: [0, 0, 0], rotateZ: [0, 0, 43], translateZ: 0, opacity: [0, 1, 1] },
            transition: {
                duration: 2,
                times: [0, 0.5, 1],
                delay: 0.3,
                ease: easeInOut,
            },
            initial: { opacity: 0 },
        },
        top: {
            animate: { opacity: 0.4, translateX: c.top.t.initial, translateY: c.top.t.initial },
            transition: {
                duration: 1,
                delay: 2,
                ease: easeInOut,
            },
            initial: { opacity: 0 },
        },
        topOverlay: {
            animate: { translateX: c.top.t.initial, translateY: c.top.t.initial },
        },
        bottom: {
            animate: { translateX: c.bottom.t.initial, translateY: c.bottom.t.initial },
            transition: {
                duration: 1,
                delay: 2,
                ease: easeInOut,
            },
            initial: {},
        },
        bottomOverlay: {
            initial: { opacity: 0 },
        }
    }), []);

    const styleTop = {
        translateX: useTransform(scrollYProgress,
            [s.topFocus, s.topHover, s.initial, s.bottomMerged, s.bottomFocus],
            [c.top.t.centered, c.top.t.hover, c.top.t.initial, c.top.t.merged, c.top.t.centered],
            { ease: easeInOut }
        ),
        translateY: useTransform(scrollYProgress,
            [s.topFocus, s.topHover, s.initial, s.bottomMerged, s.bottomFocus],
            [c.top.t.hover, c.top.t.hover, c.top.t.initial, c.top.t.merged, c.top.t.bottomFocus],
            { ease: easeInOut }
        ),
        translateZ: 0,
        opacity: useTransform(scrollYProgress,
            [s.topFocus, s.topHover, s.initial, s.bottomMerged],
            [1, 0.8, 0.4, 0],
            { ease: easeInOut }
        ),
        borderRadius: `${borderRadiusPx}px`,
    }
    const styleTopDetails = {
        ...styleTop,
        backgroundColor: 'none',
        opacity: useTransform(scrollYProgress,
            [s.topFocus, s.topHover],
            [1, 0],
            { ease: easeInOut }
        ),
        zIndex: ZIndexes.landingPageAnim,
    }
    const styleTopTexture = {
        ...styleTop,
        backgroundColor: 'none',
        opacity: useTransform(scrollYProgress,
            [s.topFocus, s.topHover, s.initial],
            [0, 0.8, 0],
            { ease: easeInOut }
        ),
        zIndex: ZIndexes.landingPageAnim,
    }

    const styleBottom = {
        translateX: useTransform(scrollYProgress,
            [s.topFocus, s.topHover, s.initial, s.bottomMerged, s.bottomFocus],
            [c.bottom.t.exitX, c.bottom.t.initial, c.bottom.t.initial, c.bottom.t.merged, c.bottom.t.centered],
            { ease: easeInOut }
        ),
        translateY: useTransform(scrollYProgress,
            [s.topFocus, s.topHover, s.initial, s.bottomMerged, s.bottomFocus],
            [c.bottom.t.exitY, c.bottom.t.initial, c.bottom.t.initial, c.bottom.t.merged, c.bottom.t.focus],
            { ease: easeInOut }
        ),
        translateZ: 0,
        opacity: useTransform(scrollYProgress,
            [s.topHover - 0.1, s.topHover],
            [0, 1]
        ),
    }
    const styleBottomOverlay = {
        ...styleBottom,
        opacity: useTransform(scrollYProgress,
            [s.bottomFocus - 0.1, s.bottomFocus],
            [0, 1]
        ),
        zIndex: ZIndexes.landingPageAnim,
    }
    const styleParent = {
        rotateX: useTransform(scrollYProgress,
            [s.topFocus, s.topHover - 0.1, s.bottomMerged + 0.1, s.bottomFocus],
            [0, c.parent.rotate.X, c.parent.rotate.X, 0],
            { ease: easeInOut }
        ),
        rotateY: 0,
        rotateZ: useTransform(scrollYProgress,
            [s.topFocus, s.topHover, s.bottomMerged, s.bottomFocus],
            [0, c.parent.rotate.Z, c.parent.rotate.Z, 0],
            { ease: easeInOut }
        ),
        translateZ: 0,
    }

    return (
        <Box position='absolute' left={`calc(50% - ${animSizePx / 2}px)`} top={`calc(50% - ${animSizePx / 2}px)`}>
            <motion.div
                style={{
                    ...(initialAnimationComplete ? styleParent : {}),
                    width: `${animSizePx}px`,
                    height: `${animSizePx}px`,
                }}
                animate={initialAnimations.parent.animate}
                transition={initialAnimations.parent.transition}
                initial={initialAnimations.parent.initial}
            >
                <motion.div
                    className='top-layer top-layer-overlay top-layer-details'
                    style={initialAnimationComplete ? styleTopDetails : {}}
                    animate={initialAnimations.topOverlay.animate}
                    transition={initialAnimations.top.transition}
                    initial={initialAnimations.top.initial}
                    onAnimationComplete={setInitialAnimationComplete}
                >
                    <DetailsContent variant='details' animSizePx={animSizePx} borderRadiusPx={borderRadiusPx} />
                </motion.div>
                <motion.div
                    className='top-layer top-layer-overlay top-layer-texture'
                    style={initialAnimationComplete ? styleTopTexture : {}}
                    animate={initialAnimations.topOverlay.animate}
                    transition={initialAnimations.top.transition}
                    initial={initialAnimations.top.initial}
                    onAnimationComplete={setInitialAnimationComplete}
                >
                    <DetailsContent variant='texture' animSizePx={animSizePx} borderRadiusPx={borderRadiusPx} />
                </motion.div>
                <motion.div
                    className='top-layer'
                    style={initialAnimationComplete ? styleTop : { borderRadius: `${borderRadiusPx}px` }}
                    animate={initialAnimations.top.animate}
                    transition={initialAnimations.top.transition}
                    initial={initialAnimations.top.initial}
                    onAnimationComplete={setInitialAnimationComplete}
                >
                </motion.div>

                <motion.div
                    className='bottom-layer bottom-layer-overlay'
                    style={initialAnimationComplete ? styleBottomOverlay : {}}
                    animate={initialAnimations.bottom.animate}
                    transition={initialAnimations.bottom.transition}
                    initial={initialAnimations.bottomOverlay.initial}
                >
                    <DetailsWatermark animSizePx={animSizePx} borderRadiusPx={borderRadiusPx} />
                </motion.div>
                <motion.div
                    className='bottom-layer'
                    style={initialAnimationComplete ? styleBottom : {}}
                    animate={initialAnimations.bottom.animate}
                    transition={initialAnimations.bottom.transition}
                    initial={initialAnimations.bottom.initial}
                >
                    <Box component='img' src={image_square_2} width='100%' alt='trufo' sx={{ borderRadius: `${borderRadiusPx}px` }} />
                </motion.div>
            </motion.div>
        </Box>
    );
}
