import { Box, Divider } from "@mui/material";
import Login from "./login";
import BicolorLogo, { useBicolorLogo } from "./bicolor-logo";
import WatermarkAnim from "./watermark-anim";
import { COMPACT_VIEW_WIDTH_PX, EXTRA_COMPACT_VIEW_WIDTH_PX, MAX_ANIM_SIZE_PX, MIN_ANIM_SIZE_PX, RIGHT_COL_MIN_WIDTH_PX } from "./types-and-constants";
import { Colors, TrufoLink, ZIndexes } from "@components";
import { useEffect, useRef, useState } from "react";
import { CompactMenu } from "@components";
import DetailPage from "./detail-page";
import { useAnimate, motion, ValueAnimationTransition } from 'framer-motion';
import MenuIcon from '@mui/icons-material/Menu';
import { BLOG_LINK, CONTACT_US_EMAIL, DISCORD_LINK, LOGIN_ROUTE, MAIN_APP_ROUTE, PRIVACY_POLICY_ROUTE, TERMS_OF_SERVICE_ROUTE, X_LINK } from "@/app-routes";
import { useGetUser } from "@/hooks";

type LandingPagePages = 'details' | 'home';
interface LandingPageProps {
    page?: LandingPagePages,
}

export default function LandingPage({ page }: LandingPageProps) {

    const { refWhite, refBlack, animateWhiteToBlack, animateBlackToWhite } = useBicolorLogo();
    // const { FaqPage, openCloseFaqPage } = useFaqPage(animateWhiteToBlack, animateBlackToWhite);

    const pageContainer = useRef<HTMLElement>(null);
    const animContainer = useRef<HTMLElement>(null);
    const [animSizePx, setAnimSizePx] = useState<number>();
    const [compact, setCompact] = useState<boolean>(false); // right column dissapears and becomes a menu button
    const [extraCompact, setExtraCompact] = useState<boolean>(false); // logo + menu button dissapear in detail page
    const [onDetailPage, setOnDetailPage] = useState<boolean>(page === 'details' ? true : false);
    const [compactViewEvaluated, setCompactViewEvaluated] = useState<boolean>(false);

    const { getUser: getTrufoUser, data: trufoUserData, error: getTrufoUserError } = useGetUser();
    useEffect(() => { getTrufoUser() }, [getTrufoUser])
    const loggedIn = trufoUserData && !getTrufoUserError;

    const topLinks: Record<string, string> = {
        'blog': BLOG_LINK,
        'discord': DISCORD_LINK,
        'x/twitter': X_LINK,
        'contact us': CONTACT_US_EMAIL,
    };
    const bottomLinks: Record<string, string> = {
        'privacy policy': PRIVACY_POLICY_ROUTE,
        'terms of service': TERMS_OF_SERVICE_ROUTE,
    }

    // set animSizePx (animation size) based on anim container height and width
    useEffect(() => {
        if (!animContainer.current) return;
        const resizeObserver = new ResizeObserver((elementArr) => {
            // Whenever the size of the element changes:
            const element = elementArr?.[0]?.borderBoxSize?.[0];
            const height = element?.blockSize;
            const width = element?.inlineSize;
            const pageWidth = pageContainer?.current?.offsetWidth;
            if (height && width) {
                // set anim size
                const newSizeFromHeight = (height / (MIN_ANIM_SIZE_PX + 300)) * MIN_ANIM_SIZE_PX;
                const newSizeFromWidth = (width / (MIN_ANIM_SIZE_PX + 200)) * MIN_ANIM_SIZE_PX;
                const newSize = Math.max(Math.min(newSizeFromHeight, newSizeFromWidth, MAX_ANIM_SIZE_PX), MIN_ANIM_SIZE_PX);
                compactViewEvaluated && setAnimSizePx(newSize); // don't let anim be initialized until proper width is set by compact view

                // set compact
                if (pageWidth && pageWidth > COMPACT_VIEW_WIDTH_PX) {
                    setCompact(false);
                    setExtraCompact(false);
                } else {
                    setCompact(true);
                    if (pageWidth && pageWidth < EXTRA_COMPACT_VIEW_WIDTH_PX) {
                        setExtraCompact(true);
                    } else {
                        setExtraCompact(false);
                    }
                }
                setCompactViewEvaluated(true);
            }
        });
        resizeObserver.observe(animContainer.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, [compactViewEvaluated]);

    const [watermarkAnimContainer, animateWatermarkAnimContainer] = useAnimate();
    const [menuIcon, animateMenuIcon] = useAnimate();

    return (
        <Box
            ref={pageContainer}
            width='100dvw' height='100dvh'
            // maxWidth='2000px'
            display='flex' flexDirection='row' alignSelf='stretch' flex={1}
        >

            {/* Left Column */}
            <Box
                ref={animContainer}
                position='relative' width='100%' height='100%'
                display='flex' flexGrow={1} justifyContent='center' alignItems='center'
                sx={{ backgroundColor: Colors.dark }}
            >
                <motion.div style={{ width: '100%', height: '100%' }} ref={watermarkAnimContainer}>
                    {animSizePx && <WatermarkAnim animSizePx={animSizePx} tabIndex={onDetailPage ? -1 : 0} />}
                </motion.div>
                <CompactMenu
                    links={{
                        ...topLinks,
                        ...bottomLinks,
                        ...(loggedIn ? { 'go to app': MAIN_APP_ROUTE, } : { 'log in': LOGIN_ROUTE, })
                    }}
                    containerProps={{
                        position: 'absolute', right: '18px', top: '16px', zIndex: ZIndexes.navbar,
                        visibility: (compact && !(extraCompact && onDetailPage)) ? 'visible' : 'hidden'
                    }}
                >
                    <motion.div
                        ref={menuIcon}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <MenuIcon />
                    </motion.div>
                </CompactMenu>
                <DetailPage
                    onDetailPage={onDetailPage}
                    setOnDetailPage={setOnDetailPage}
                    animateLogoWhiteToBlack={animateWhiteToBlack}
                    animateLogoBlackToWhite={animateBlackToWhite}
                    animateWatermarkAnimContainer={
                        (to: any, options?: ValueAnimationTransition<any> | undefined) =>
                            animateWatermarkAnimContainer(watermarkAnimContainer.current, to, options)
                    }
                    animateMenuIcon={
                        (to: any, options?: ValueAnimationTransition<any> | undefined) =>
                            animateMenuIcon(menuIcon.current, to, options)
                    }
                />
                <BicolorLogo refWhite={refWhite} refBlack={refBlack} isHidden={extraCompact && onDetailPage} />
            </Box>

            {/* Right Column; only show if compact = false */}
            {compactViewEvaluated &&
                <Box
                    display={compact ? 'none' : 'flex'} flexGrow={0} minWidth={`${RIGHT_COL_MIN_WIDTH_PX}px`} alignSelf='stretch'
                    flexDirection='column' justifyContent='space-between'
                    sx={{ backgroundColor: Colors.light, color: Colors.dark }}
                >
                    <Box />
                    <Box display='flex' flexDirection='column' gap='10px'>
                        <Login onSuccessVariant='button' />
                        <Divider variant='middle' sx={{ marginTop: '20px', marginBottom: '10px' }} />
                        <>
                            {/* <Box fontSize={FontSizes.header3}>
                                Links
                            </Box> */}
                            <Box display='flex' flexDirection='row' gap='10px' justifyContent='center'>
                                {Object.keys(topLinks).map(linkName => <TrufoLink key={linkName} href={topLinks[linkName]} newtab>{linkName}</TrufoLink>)}
                            </Box>
                        </>
                    </Box>
                    <Box display='flex' flexDirection='column' marginBottom='10px' gap='10px'>
                        {/* <Box display='flex' justifyContent='center' gap='20px'>
                            {Object.keys(bottomLinks).map(linkName => <TrufoLink href={bottomLinks[linkName]}>{linkName}</TrufoLink>)}
                        </Box> */}
                    </Box>
                </Box>
            }

        </Box>
    );
}