import { Box } from '@mui/material';
import { motion, useTransform, easeInOut, MotionValue } from 'framer-motion';
import { AnimStage, SCROLL_POSITIONS } from '../types-and-constants';
import { useEffect, useRef } from 'react';
import { Colors, ZIndexes } from '@components';

type AnimScrollProps = {
    scrollYProgress: MotionValue<number>,
    initialAnimationComplete: boolean,
    scrollContainer: React.RefObject<HTMLElement>,
    tabIndex?: number,
}

export default function AnimScroll({ scrollYProgress, initialAnimationComplete, scrollContainer, tabIndex }: AnimScrollProps) {

    const s = SCROLL_POSITIONS;
    const scrollContainerHeights = ['180dvh', '20dvh', '20dvh', '20dvh', '20dvh', '100dvh'];

    const scrollDivs = {
        1: useRef<HTMLElement>(null),
        2: useRef<HTMLElement>(null),
        3: useRef<HTMLElement>(null),
        4: useRef<HTMLElement>(null),
        5: useRef<HTMLElement>(null)
    };
    const initialScrollDiv = scrollDivs[3];
    const scrollContent = useRef<HTMLElement>(null);

    const useScrollDotStyle = (stage: AnimStage) => ({
        width: '10px', height: '10px', borderRadius: '5px', backgroundColor: Colors.white, cursor: 'pointer',
        opacity: useTransform(scrollYProgress, [s[stage] - 0.2, s[stage], s[stage] + 0.2],
            [0.3, 1, 0.3],
            { ease: easeInOut }
        ),
    });

    const useScrollDotOnClick = (scrollTo: React.RefObject<HTMLElement> | number | 'bottom' | 'top') => {
        return () => {
            if (typeof scrollTo === 'number') {
                scrollContainer.current?.scroll(0, scrollTo);
            } else if (scrollTo === 'bottom' || scrollTo === 'top') {
                const contentHeight = scrollContent.current?.offsetHeight;
                if (scrollTo === 'bottom' && contentHeight) { scrollContainer.current?.scrollTo({ top: contentHeight, behavior: 'smooth' }); }
                if (scrollTo === 'top') { scrollContainer.current?.scrollTo({ top: 0, behavior: 'smooth' }) }
            } else {
                scrollTo.current?.scrollIntoView({ behavior: 'smooth' });
            }
        };
    };

    // scroll to initial component
    useEffect(() => {
        initialScrollDiv.current?.scrollIntoView();
    }, [initialScrollDiv]);

    return (
        <>
            {/* scroll containers */}
            <Box
                ref={scrollContainer}
                overflow={initialAnimationComplete ? 'scroll' : 'hidden'}
                zIndex={ZIndexes.landingPageHomePage}
                sx={{ scrollSnapType: 'y mandatory' }}
                height='100%' width='100%' position='absolute' left='0px' top='0px'
                className='disable-scrollbar'
            >
                <Box ref={scrollContent} width='100%' height={scrollContainerHeights[0]}>
                    <Box ref={scrollDivs[1]} height={scrollContainerHeights[1]} width="100%" sx={{ scrollSnapAlign: 'start' }} />
                    <Box ref={scrollDivs[2]} height={scrollContainerHeights[2]} width="100%" sx={{ scrollSnapAlign: 'start' }} />
                    <Box ref={scrollDivs[3]} height={scrollContainerHeights[3]} width="100%" sx={{ scrollSnapAlign: 'start' }} />
                    <Box ref={scrollDivs[4]} height={scrollContainerHeights[4]} width="100%" sx={{ scrollSnapAlign: 'start' }} />
                    <Box ref={scrollDivs[5]} height={scrollContainerHeights[5]} width="100%" sx={{ scrollSnapAlign: 'start' }} />
                </Box>
            </Box>

            {/* scrollbar */}
            <Box
                sx={{ opacity: initialAnimationComplete ? 1 : 0 }}
                position='absolute' right='10px' top='0px' height='100%'
                display='flex' flexDirection='column' gap='10px' justifyContent='center' alignItems='center'
                zIndex={ZIndexes.landingPageClickable} // ZIndexes.landingPageHomePage
            >
                <motion.div
                    style={useScrollDotStyle('topFocus')} tabIndex={tabIndex || 0}
                    onClick={useScrollDotOnClick(scrollDivs[1])} onFocusCapture={useScrollDotOnClick(scrollDivs[1])}
                />
                <motion.div
                    style={useScrollDotStyle('topHover')} tabIndex={tabIndex || 0}
                    onClick={useScrollDotOnClick(scrollDivs[2])} onFocusCapture={useScrollDotOnClick(scrollDivs[2])}
                />
                <motion.div
                    style={useScrollDotStyle('initial')} tabIndex={tabIndex || 0}
                    onClick={useScrollDotOnClick(scrollDivs[3])} onFocusCapture={useScrollDotOnClick(scrollDivs[3])}
                />
                <motion.div
                    style={useScrollDotStyle('bottomMerged')} tabIndex={tabIndex || 0}
                    onClick={useScrollDotOnClick(scrollDivs[4])} onFocusCapture={useScrollDotOnClick(scrollDivs[4])}
                />
                <motion.div
                    style={useScrollDotStyle('bottomFocus')} tabIndex={tabIndex || 0}
                    onClick={useScrollDotOnClick('bottom')} onFocusCapture={useScrollDotOnClick('bottom')}
                />
            </Box>

        </>
    );
}