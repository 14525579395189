import { MAIN_APP_ROUTE } from "@/app-routes";
import { FontSizes, Fonts, TrufoButton } from "@/components";
import { Box, BoxProps } from "@mui/material";

type IntroTextProps = {
    tabIndex?: number,
    containerProps?: BoxProps,
}

const headerStyle = {
    fontSize: FontSizes.header1,
    fontWeight: 'bold',
    lineHeight: '90%',
    // color: Colors.accentDark,
}
const subheaderStyle = {
    fontSize: FontSizes.header1 - 10,
    fontWeight: 'bold',
    lineHeight: '90%',
    // color: Colors.accentDark,
}
const bodyStyle = {
    fontSize: FontSizes.body1,
    fontFamily: Fonts.monospace,
}

const minTextWidthPx = 200;

export default function IntroText({ tabIndex, containerProps }: IntroTextProps) {
    return (
        <Box
            display='flex' flexDirection='column' flexWrap='wrap'
            {...containerProps}
        >
            <Box display='flex' flexDirection='column' marginBottom='40px'>
                <Box {...headerStyle} maxWidth='80%'>Don't let AI</Box>
                <Box {...headerStyle} maxWidth='80%' marginBottom='12px'>mess with your content.</Box>
                <Box {...bodyStyle} maxWidth='90%'>The explosion of generative AI tools is changing digital media. Your content can now be altered or adapted with a simple prompt. This erodes both trust in what you see and value in what you create. If this is concerning, Trufo can help.</Box>
            </Box>
            <Box display='flex' flexDirection='column' marginBottom='40px' alignItems='flex-end'>
                <Box {...headerStyle} textAlign='right'>Protect yourself with</Box>
                <Box {...headerStyle} textAlign='right' marginBottom='12px'>cryptographic watermarks.</Box>
                <Box {...bodyStyle} maxWidth='92%' textAlign='right'>Our cryptographic watermarks act as invisible stamps of authenticity. Simply watermark your content prior to distribution. Anyone can decode the content to authenticate it, even with some changes in distribution (e.g. compression, resizing, cropping, overlays).</Box>
            </Box>
            <Box marginBottom='20px'>
                <Box {...headerStyle} marginBottom='12px'>What you get:</Box>
                <Box>
                    <Box
                        marginBottom='12px' display='flex' flexWrap='wrap' gap='10px' marginTop='auto'
                    // alignItems='center'
                    >
                        <Box {...subheaderStyle}>1. Ownership</Box>
                        <Box {...bodyStyle} flex={1} minWidth={`${minTextWidthPx}px`}>is embedded in your content, and cannot be forged by others.</Box>
                    </Box>
                    <Box marginBottom='12px' display='flex' flexWrap='wrap' gap='10px'>
                        <Box {...subheaderStyle}>2. Control</Box>
                        <Box {...bodyStyle} flex={1} minWidth={`${minTextWidthPx + 42}px`}>how your content is used and distributed by attaching a personal website, a copyright license, and more.</Box>
                    </Box>
                    <Box marginBottom='12px' display='flex' flexWrap='wrap' gap='10px'>
                        <Box {...subheaderStyle}>3. Analysis</Box>
                        <Box {...bodyStyle} flex={1} minWidth={`${minTextWidthPx + 34.5}px`}>of not just if but also what and where alterations were made to the content you decode, without needing a copy of the original.</Box>
                    </Box>
                </Box>
            </Box>
            <Box display='flex' justifyContent='center'>
                <TrufoButton
                    label='Try It Out'
                    colorVariant='dark'
                    onClick={() => window.location.href = MAIN_APP_ROUTE}
                    fontSize={FontSizes.header3}
                    sx={{ padding: '10px 20px 10px 20px', borderRadius: '4px' }}
                    tabIndex={tabIndex || 0}
                />
            </Box>
        </Box>
    );
}