import { Box } from "@mui/material";
import trufo_white from '@assets/trufo_white.png';
import trufo_black from '@assets/trufo_black.png';
import { LogoColor } from "./bicolor-logo";

interface LogoProps {
    color?: LogoColor,
    onClick?: () => void,
}

export default function Logo({ color, onClick }: LogoProps) {
    return (
        <>
            {color === 'white' ?
                (<Box position='absolute' left='24px' top='11px' onClick={onClick} sx={{ cursor: 'pointer' }}>
                    <Box component='img' src={trufo_white} height='50px' alt='trufo' />
                </Box>) :
                (<Box position='absolute' left='8px' top='10px' onClick={onClick} sx={{ cursor: 'pointer' }}>
                    <Box component='img' src={trufo_black} height='50px' alt='trufo' />
                </Box>)
            }
        </>
    );
}