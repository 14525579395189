import { Box, Button } from "@mui/material";
import { useAnimate, motion, ValueAnimationTransition } from 'framer-motion';
import { BoxShadows, Colors, FontSizes, Fonts, GradientBox, ZIndexes } from "@/components";
import { NewsletterSignup, ArticleLinks, Footer, IframeEmbedder, IntroText, AboutTrufo } from './sections'
import { useEffect } from "react";

interface DetailPageProps {
    onDetailPage: boolean;
    setOnDetailPage: (onDetailPage: boolean) => void;
    animateLogoWhiteToBlack: (delay?: number, duration?: number) => void;
    animateLogoBlackToWhite: (delay?: number, duration?: number) => void;
    animateWatermarkAnimContainer: (to: any, options?: ValueAnimationTransition<any> | undefined) => void;
    animateMenuIcon: (to: any, options?: ValueAnimationTransition<any> | undefined) => void;
}

export default function DetailPage({ onDetailPage, setOnDetailPage, animateLogoWhiteToBlack, animateLogoBlackToWhite, animateWatermarkAnimContainer, animateMenuIcon }: DetailPageProps) {

    const buttonWidthPx = 150;
    const buttonHeightPx = 30;
    const buttonMarginPx = 20;

    const getButtonStyles = (pointing: 'down' | 'up') => (
        {
            width: `${buttonWidthPx}px`,
            height: `${buttonHeightPx}px`,
            cursor: 'pointer',
            color: pointing === 'down' ? Colors.white : Colors.black,
            backgroundColor: pointing === 'down' ? 'none' : Colors.white,
            border: '1px solid',
            borderRadius: '2px',
            fontFamily: Fonts.monospace,
            display: 'flex', alignItems: 'center', justifyContent: 'center',
            '&.MuiButton-root': { opacity: 1 },
            '&:hover': {
                backgroundColor: pointing === 'down' ? Colors.accentLight20 : Colors.light,
                boxShadow: BoxShadows.dense1,
            },
        }
    );

    const [refPageContainer, animatePageContainer] = useAnimate();
    const [refButtonOpen, animateButtonOpen] = useAnimate();
    const [refButtonClose, animateButtonClose] = useAnimate();

    const logoAnimDuration = 0.2;

    const animatePageOpen = () => {
        const height = refPageContainer?.current?.offsetHeight;

        // page flips up
        animatePageContainer(refPageContainer.current, { translateY: 0 }, { ease: 'easeInOut', duration: 1 });
        animateWatermarkAnimContainer({ translateY: '-100%' }, { ease: 'easeInOut', duration: 1 });

        // open button dissapears and close button appears
        animateButtonOpen(refButtonOpen.current, { opacity: 0 }, { duration: 0.5, delay: 0.1 });
        animateButtonOpen(refButtonOpen.current, { display: 'none' }, { delay: 0.6 });
        animateButtonClose(refButtonClose.current, { opacity: 0.7 }, { duration: 0.5, delay: 0.1 });
        animateButtonClose(refButtonClose.current, { display: 'block' }, { delay: 0.1 });

        // logos and icons become dark (since background becomes light)
        const logoAnimDelay = (height && height > 700) ? 0.8 : 0.7; // taller = page gets to logo slower on open
        animateLogoWhiteToBlack(logoAnimDelay, logoAnimDuration);
        animateMenuIcon({ color: Colors.black }, { delay: logoAnimDelay, duration: logoAnimDuration });

        // set page open
        setTimeout(() => setOnDetailPage(true), logoAnimDelay * 1000);
    }

    const animatePageClose = () => {
        const height = refPageContainer?.current?.offsetHeight;

        // page flips down
        animatePageContainer(refPageContainer.current, { translateY: '100%' }, { ease: 'easeInOut', duration: 1 });
        animateWatermarkAnimContainer({ translateY: 0 }, { ease: 'easeInOut', duration: 1 });

        // close button dissapears and open button appears
        animateButtonOpen(refButtonOpen.current, { opacity: 0.7 }, { duration: 0.5, delay: 0.1 });
        animateButtonOpen(refButtonOpen.current, { display: 'block' }, { delay: 0.1 });
        animateButtonClose(refButtonClose.current, { opacity: 0 }, { duration: 0.5, delay: 0.1 });
        animateButtonClose(refButtonClose.current, { display: 'none' }, { delay: 0.6 });

        // logos and icons become light (since background becomes dark)
        const logoAnimDelay = (height && height > 700) ? 0.1 : 0.2; // taller = page gets to logo faster on close
        animateLogoBlackToWhite(logoAnimDelay, logoAnimDuration);
        animateMenuIcon({ color: Colors.white }, { delay: logoAnimDelay, duration: logoAnimDuration });

        // set page close
        setTimeout(() => setOnDetailPage(false), logoAnimDelay * 1000);
    }

    useEffect(() => { if (onDetailPage) { animatePageOpen() } }, [])

    const getSectionHeader = (title: string) => {
        return <Box fontSize={FontSizes.header2} marginBottom='20px'>[ {title} ]</Box>
    }
    const tabIndex = onDetailPage ? 0 : -1;
    const containerProps = { marginBottom: '60px', width: '88%' };
    const sectionStyle = {
        tabIndex: tabIndex,
        containerProps: containerProps,
    }

    const bodyMaxWidth = '1500px';

    return (
        <motion.div
            ref={refPageContainer}
            style={{
                position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%',
                zIndex: ZIndexes.landingPageDetailPage, backgroundColor: Colors.white, translateY: '100%',
            }}
        >
            {/* Background white box to make buttons more visible */}
            <GradientBox colorVariant='white' positionVariant='top' />

            <Box
                width='100%' paddingTop='80px' height='calc(100% - 80px)'
                textAlign='left' sx={{ overflowY: 'scroll' }}
                color={Colors.black70}
            >
                <Box
                    display='flex' flexDirection='column' justifyContent='start' alignItems='center' maxWidth={bodyMaxWidth}
                    marginLeft='auto' marginRight='auto'
                >
                    <IntroText tabIndex={tabIndex} containerProps={{ ...containerProps, marginTop: '20px', marginBottom: '70px' }} />

                    {getSectionHeader('about trufo')}
                    <AboutTrufo {...sectionStyle} />

                    {getSectionHeader('newsletter')}
                    <NewsletterSignup {...sectionStyle} />

                    {getSectionHeader('trufo 101')}
                    <IframeEmbedder
                        variant='googleSlides'
                        src="https://docs.google.com/presentation/d/e/2PACX-1vQNoDcJKiYBul2QE5JfCDnnXQlDbqgJnG8R8VlOUYgTsJ0DfAixCCf-voaYxfZ4HLM7Je5bfrRuSVUA/embed?start=false&loop=false&delayms=3000"
                        {...sectionStyle}
                    />

                    {getSectionHeader('concept video')}
                    <IframeEmbedder
                        variant='youtube'
                        src="https://www.youtube.com/embed/DVKWUpk9Klg?si=WxGq609O3mZ6tBn7"
                        aspectRatio={560 / 315}
                        {...sectionStyle}
                    />

                    {getSectionHeader('blog')}
                    <ArticleLinks {...sectionStyle} />
                </Box>
                <Footer tabIndex={tabIndex} maxWidth={bodyMaxWidth} />
            </Box>

            {/* Button to open */}
            <Box
                ref={refButtonOpen}
                onClick={animatePageOpen}
                position='absolute' zIndex={ZIndexes.landingPageClickable}
                left={`calc(50% - ${buttonWidthPx / 2}px)`} top={`-${buttonMarginPx + buttonHeightPx}px`}
            >
                <Button sx={getButtonStyles('down')} tabIndex={onDetailPage ? -1 : 0}>▼ more details</Button>
            </Box>

            {/* Button to close */}
            <Box
                ref={refButtonClose}
                onClick={animatePageClose}
                position='absolute' zIndex={ZIndexes.landingPageClickable} display='none'
                left={`calc(50% - ${buttonWidthPx / 2}px)`} top={`${buttonMarginPx}px`}
            >
                <Button sx={getButtonStyles('up')} tabIndex={onDetailPage ? 0 : -1}>▲ homepage</Button>
            </Box>
        </motion.div >
    )
}