import { Box } from "@mui/material";
import { motion, useAnimate } from 'framer-motion';
import trufo_white from '@assets/trufo_white.png';
import trufo_black from '@assets/trufo_black.png';
import { ZIndexes } from "@/components";

export type LogoColor = 'black' | 'white';

export const useBicolorLogo = () => {
    const [refWhite, animateWhite] = useAnimate();
    const [refBlack, animateBlack] = useAnimate();
    const animateWhiteToBlack = (delay?: number, duration?: number) => {
        animateBlack(refBlack.current, { opacity: 1, display: 'block' }, { delay: delay || 0, duration: duration || 0.1, ease: 'easeInOut' });
        animateWhite(refWhite.current, { opacity: 0, display: 'none' }, { delay: delay || 0, duration: duration || 0.1, ease: 'easeInOut' });
    }
    const animateBlackToWhite = (delay?: number, duration?: number) => {
        animateBlack(refBlack.current, { opacity: 0, display: 'none' }, { delay: delay || 0, duration: duration || 0.1, ease: 'easeInOut' });
        animateWhite(refWhite.current, { opacity: 1, display: 'block' }, { delay: delay || 0, duration: duration || 0.1, ease: 'easeInOut' });
    }
    return { refWhite, refBlack, animateWhiteToBlack, animateBlackToWhite };
}

export type BicolorLogoProps = {
    refBlack: React.RefObject<HTMLDivElement>,
    refWhite: React.RefObject<HTMLDivElement>,
    defaultColor?: LogoColor,
    isHidden?: boolean,
}

export default function BicolorLogo({ refBlack, refWhite, defaultColor, isHidden }: BicolorLogoProps) {
    return (
        <>
            <Box position='absolute' left='24px' top='11px' visibility={isHidden ? 'hidden' : 'visible'}>
                <motion.div
                    ref={refWhite}
                    style={{
                        opacity: (defaultColor === 'white' || defaultColor === undefined) ? 1 : 0,
                        display: (defaultColor === 'white' || defaultColor === undefined) ? 'block' : 'none',
                    }}
                >
                    <Box component='img' src={trufo_white} height='50px' alt='trufo' />
                </motion.div>
            </Box>
            <Box position='absolute' left='8px' top='10px' zIndex={ZIndexes.landingPageNavbar} visibility={isHidden ? 'hidden' : 'visible'}>
                <motion.div
                    ref={refBlack}
                    style={{
                        opacity: defaultColor === 'black' ? 1 : 0,
                        display: defaultColor === 'black' ? 'block' : 'none',
                    }}
                >
                    <Box component='img' src={trufo_black} height='50px' alt='trufo' />
                </motion.div>
            </Box>
        </>
    );
}