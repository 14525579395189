import { BoxShadows, Colors, Fonts } from "@/components";
import { Box } from "@mui/material";
import { useState } from "react";
import visible_watermark_sample from '@assets/visible_watermark_sample.png';

interface DetailsWatermarkProps {
    animSizePx: number,
    borderRadiusPx: number,
}
export default function DetailsWatermark({ animSizePx, borderRadiusPx }: DetailsWatermarkProps) {
    type WatermarkType = 'patterned' | 'invisible' | 'metadata';
    const [hoverItem, setHoverItem] = useState<WatermarkType>();
    const hoverStyle = { backgroundColor: Colors.white30, width: '100%', flex: 2 };

    const fontSizeSmall = animSizePx / 20;
    const fontSizeMedium = animSizePx / 15;
    const fontSizeLarge = animSizePx / 10;
    const headerMarginTop = fontSizeSmall;
    const headerMarginSide = fontSizeSmall / 2;
    const descriptionMargin = fontSizeSmall / 2;

    const getFontSize = (variant: WatermarkType) => hoverItem === variant ? fontSizeLarge : hoverItem !== undefined ? fontSizeSmall : fontSizeMedium;
    const getDescriptionDisplay = (variant: WatermarkType) => hoverItem === variant ? 'flex' : 'none';

    return (
        <Box
            height='100%' display='flex' flexDirection='row' borderRadius={`${borderRadiusPx}px`} sx={{ backgroundColor: Colors.white10 }}
        >
            <Box
                sx={{
                    flex: 1, transitionDuration: '0.3s', ":hover": hoverStyle,
                    borderRight: 1, borderColor: Colors.black,
                }}
                display='flex' flexDirection='column' justifyContent='space-between'
                borderRadius={`${borderRadiusPx}px 0px 0px ${borderRadiusPx}px`} overflow='hidden' fontSize={getFontSize('invisible')}
                onMouseEnter={() => setHoverItem('invisible')} onMouseLeave={() => setHoverItem(undefined)}
            >
                <Box marginTop={`${headerMarginTop}px`} marginLeft={`${headerMarginSide}px`} display='flex' flexDirection='column'>
                    <Box>invisible</Box>
                    {/* <Box display={getDescriptionDisplay('invisible')} fontSize={fontSizeSmall} margin='5px'>
                        description for invisible watermark
                    </Box> */}
                </Box>
                <Box></Box>
            </Box>
            <Box
                sx={{
                    flex: 1, transitionDuration: '0.3s', ":hover": hoverStyle,
                    borderRight: 1, borderColor: Colors.black,
                }}
                display='flex' flexDirection='column' justifyContent='space-between' fontSize={getFontSize('metadata')}
                onMouseEnter={() => setHoverItem('metadata')} onMouseLeave={() => setHoverItem(undefined)}
            >
                <Box marginTop={`${headerMarginTop}px`} display='flex' flexDirection='column'>
                    <Box>metadata</Box>
                    {/* <Box display={getDescriptionDisplay('metadata')} fontSize={fontSizeSmall} margin='5px'>
                        description for metadata
                    </Box> */}
                </Box>
                <Box
                    height={`${fontSizeLarge + 20}px`} margin={`${descriptionMargin}px`} borderRadius={`${descriptionMargin}px`}
                    display='flex' justifyContent='center' alignItems='center'
                    border={1} borderColor={Colors.black70} boxShadow={BoxShadows.dense1}
                    sx={{ backgroundColor: Colors.black30, fontFamily: Fonts.monospace }}
                >
                    xml
                </Box>
            </Box>
            <Box
                sx={{
                    flex: 1, transitionDuration: '0.3s', ":hover": hoverStyle,
                }}
                display='flex' flexDirection='column' justifyContent='space-between'
                borderRadius={`0px ${borderRadiusPx}px ${borderRadiusPx}px 0px`} overflow='hidden' fontSize={getFontSize('patterned')}
                onMouseEnter={() => setHoverItem('patterned')} onMouseLeave={() => setHoverItem(undefined)}
            >
                <Box marginTop={`${headerMarginTop}px`} marginRight={`${headerMarginSide}px`} display='flex' flexDirection='column'>
                    <Box>patterned</Box>
                    {/* <Box display={getDescriptionDisplay('patterned')} fontSize={fontSizeSmall} margin={`${descriptionMargin}px`}>
                        description for patterned watermark
                    </Box> */}
                </Box>
                <Box height={animSizePx / 10}>
                    <Box
                        component='img' height='100%' src={visible_watermark_sample} overflow='hidden'
                        sx={{ float: 'right' }}
                    />
                </Box>
                {/* <Box height='30px' sx={{ backgroundColor: Colors.black30 }} /> */}
            </Box>
        </Box>
    )
}
