export const Colors = {
    // main
    dark: '#23232a',
    dark100: 'rgb(35, 35, 42, 1)',
    dark70: 'rgb(35, 35, 42, 0.7)',
    dark50: 'rgb(35, 35, 42, 0.5)',
    dark20: 'rgb(35, 35, 42, 0.2)',
    dark10: 'rgb(35, 35, 42, 0.1)',
    dark05: 'rgb(35, 35, 42, 0.05)',
    dark0: 'rgb(35, 35, 42, 0)',
    light: '#d1d7e0',
    light70: 'rgb(209, 215, 224, 0.7)',
    light50: 'rgb(209, 215, 224, 0.5)',
    light20: 'rgb(209, 215, 224, 0.2)',
    light10: 'rgb(209, 215, 224, 0.1)',
    light05: 'rgb(209, 215, 224, 0.05)',
    accentDark: '#331b9c', // or #063970
    accentDark70: 'rgb(51, 27, 156, 0.7)',
    accentDark50: 'rgb(51, 27, 156, 0.5)',
    accentDark20: 'rgb(51, 27, 156, 0.2)',
    accentDark10: 'rgb(51, 27, 156, 0.1)',
    accentDark05: 'rgb(51, 27, 156, 0.05)',
    accentLight: '#76b5c5', // or #1e81b0
    accentLight70: 'rgb(118, 181, 197, 0.7)',
    accentLight50: 'rgb(118, 181, 197, 0.5)',
    accentLight20: 'rgb(118, 181, 197, 0.2)',
    accentLight10: 'rgb(118, 181, 197, 0.1)',
    accentLight05: 'rgb(118, 181, 197, 0.05)',
    offWhite: '#fafafa',
    offWhite90: 'rgb(250, 250, 250, 0.9)',
    offWhite80: 'rgb(250, 250, 250, 0.8)',
    offWhite70: 'rgb(250, 250, 250, 0.7)',
    offWhite50: 'rgb(250, 250, 250, 0.5)',
    offWhite20: 'rgb(250, 250, 250, 0.2)',
    offWhite10: 'rgb(250, 250, 250, 0.1)',
    // other
    black: '#000000',
    black70: 'rgb(0, 0, 0, 0.7)',
    black50: 'rgb(0, 0, 0, 0.5)',
    black30: 'rgb(0, 0, 0, 0.3)',
    black20: 'rgb(0, 0, 0, 0.2)',
    black10: 'rgb(0, 0, 0, 0.1)',
    white: '#ffffff',
    white100: 'rgb(255, 255, 255, 1)',
    white70: 'rgb(255, 255, 255, 0.7)',
    white50: 'rgb(255, 255, 255, 0.5)',
    white30: 'rgb(255, 255, 255, 0.3)',
    white20: 'rgb(255, 255, 255, 0.2)',
    white10: 'rgb(255, 255, 255, 0.1)',
    white0: 'rgb(255, 255, 255, 0)',
    blue: '#749dcc',
    blueSky: '#95c8d8',
    blueYale: '#0e4c92',
    bluePigeon: '#7285a5',
    blueAirForce: '#598baf',
    blueSteel: '#4682b4',
    blueIndependence: '4d516d',
    blueSpace: '#1c2951',
    blueDenim: '#131e3a',
    blueDefault: '#006AE8', // or #1c84ff
    green: 'green',
    green70: 'rgb(0, 128, 0, 0.7)',
    green50: 'rgb(0, 128, 0, 0.5)',
    greenNeon: '#2fda77',
    greenSage: '#9dc183',
    greenMoss: '8a9a5b',
    greenEmerald: '#50c878',
    greenMint: '#98fb98',
    red: '#ad1f22',
    yellow: '#ffd300',
    lightGray: '#d3d3d3',
    gray: '#b5b5b5',
    darkGray: '#5e5e5e',
    transparent: 'transparent',
}

export const FontSizes = {
    header0: 60,
    header1: 40,
    header2: 25,
    header3: 20,
    header4: 15,
    body1: 15,
    body2: 12,
    body3: 10,
}

export const BoxShadows = {
    dense1: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    dense2: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    diffused1: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',

}

export const Fonts = {
    trufo: "'Jost', sans-serif",
    monospace: 'monospace',
}

export const ZIndexes = {
    base: 0,
    clickable: 1,
    alerts: 2,
    navbar: 5,
    dragOverlay: 1800, // mui modal zindex is 1300
    landingPageAlerts: 5,
    landingPageHomePage: 1,
    landingPageAnim: 2,
    landingPageDetailPage: 3,
    landingPageClickable: 4,
    landingPageNavbar: 5,
}

export const responsiveSize = (size: number) => `calc(min(${size}dvh, ${size}dvw))`

export const ResponsiveFontSizes = {
    header2: responsiveSize(2.8),
    header3: responsiveSize(2.2),
    body0: responsiveSize(1.8),
    body1: responsiveSize(1.5),
    body2: responsiveSize(1.2),
    body3: responsiveSize(1),
}

export const ResponsiveSpacing = {
    space0: responsiveSize(0.3),
    space1: responsiveSize(0.5),
    space2: responsiveSize(1),
}

export const horizontallyCenteredAbsoluteStyle = { position: 'absolute', left: '0', right: '0', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' };
export const centeredAbsoluteStyle = { position: 'absolute', left: '0', right: '0', top: '0', bottom: '0', margin: 'auto' };