import { ToggleButtonGroup, ToggleButtonGroupProps } from "@mui/material";
import { Colors, FontSizes, Fonts, ZIndexes } from "./variables";

type TrufoToggleButtonGroupColorVariant = 'light' | 'dark' | 'accentLight' | 'accentDark';

type TrufoToggleButtonGroupProps = ToggleButtonGroupProps & {
    colorVariant?: TrufoToggleButtonGroupColorVariant,
    onValueChange?: (newVal: string) => void;
}

export default function TrufoToggleButtonGroup({ colorVariant, onValueChange, children, ...muiProps }: React.PropsWithChildren<TrufoToggleButtonGroupProps>) {

    if (!colorVariant) { colorVariant = 'dark' };

    const variantStyles = {
        dark: {
            inactive: Colors.dark50,
            active: Colors.dark,
            background: Colors.dark10,
        },
        light: {
            inactive: Colors.light50,
            active: Colors.light,
            background: Colors.light10,
        },
        accentDark: {
            inactive: Colors.accentDark50,
            active: Colors.accentDark,
            background: Colors.accentDark10,
        },
        accentLight: {
            inactive: Colors.accentLight50,
            active: Colors.accentLight,
            background: Colors.accentLight10,
        },
        disabled: {
            inactive: Colors.black20,
            active: Colors.black20,
            background: Colors.dark05,
        }
    }

    return (
        <ToggleButtonGroup
            onChange={(_, val) => onValueChange && onValueChange(val)}
            {...muiProps}
            sx={{
                zIndex: ZIndexes.clickable,
                '& button': {
                    color: variantStyles[colorVariant].inactive,
                    borderColor: variantStyles[colorVariant].inactive,
                    fontFamily: Fonts.trufo,
                    fontSize: FontSizes.body2,
                    lineHeight: 1.2,
                    padding: '5px 8px',
                    transitionDuration: '0.1s',
                },
                '& button.Mui-selected:not(.Mui-disabled), & button.Mui-selected:hover, & button:hover': {
                    backgroundColor: variantStyles[colorVariant].background,
                    border: `1px solid ${variantStyles[colorVariant].active} !important`,
                    color: variantStyles[colorVariant].active,
                    // zIndex: ZIndexes.clickable,
                },
                '& button.Mui-disabled.Mui-selected': {
                    // border: `1px solid ${variantStyles['disabled'].active} !important`,
                    color: variantStyles['disabled'].active,
                    backgroundColor: variantStyles['disabled'].background,
                },
                ...muiProps?.sx,
            }}
        >
            {children}
        </ToggleButtonGroup>
    );
}