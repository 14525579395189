import { CardProps, Card } from "@mui/material";
import { BoxShadows, Colors } from "./variables";

type TrufoCardVariant = 'light' | 'dark' | 'outline';

type TrufoCardProps = CardProps & {
    trufoVariant?: TrufoCardVariant,
}

export default function TrufoCard({ trufoVariant, children, ...muiProps }: React.PropsWithChildren<TrufoCardProps>) {

    if (!trufoVariant) { trufoVariant = 'outline' };

    const variantStyles: Record<TrufoCardVariant, any> = {
        dark: {
            backgroundColor: Colors.accentDark,
        },
        light: {
            backgroundColor: Colors.accentLight,
        },
        outline: {
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            height: '180px', minWidth: '200px', flexGrow: 1, padding: '20px', borderRadius: '2px',
            outline: `1px solid ${Colors.black} !important`, border: 'none',
            backgroundColor: 'none', opacity: 0.8, boxShadow: 'none', cursor: 'pointer',
            ":focus-visible, :hover": {
                boxShadow: BoxShadows.dense1,
                transform: 'scale(1.05)',
            }
        }
    }

    return (
        <Card
            {...muiProps}
            sx={{
                ...variantStyles[trufoVariant],
                ...muiProps?.sx,
            }}
        >
            {children}
        </Card>
    );
}