import { Colors, FontSizes, GradientBox, TrufoLink } from "@/components";
import { Box } from "@mui/material";
import Logo from "../logo";
import { LANDING_PAGE_ROUTE, PRIVACY_POLICY_ROUTE } from "@/app-routes";

export default function TermsOfService() {

    return (
        <>
            {/* Background dark box to prevent logo/text collision */}
            <GradientBox colorVariant='dark' positionVariant='top' />

            <Logo color='white' onClick={() => window.location.href = LANDING_PAGE_ROUTE} />

            <Box
                width='100dvw' height='100dvh' display='flex' flexDirection='column' alignItems='center' overflow='scroll'
                sx={{
                    backgroundColor: Colors.dark, color: Colors.offWhite80, gap: '20px',
                }}
            >
                <Box fontSize={FontSizes.header2} marginTop='10dvh'>[ terms of service ]</Box>
                <Box
                    margin='5%' marginTop='2%' textAlign='left'
                    display='flex' flexDirection='column' gap='10px'
                >
                    <Box>Thank you for using the Trufo platform (the “Services”). These Terms of Service (the “Agreement”) explain what rights you have with respect to images and other assets which you might process or generate with the Services (the “Assets”), your use of the Services, and other important topics. Please read them carefully. The separate <TrufoLink href={PRIVACY_POLICY_ROUTE} trufoVariant='white'>Privacy Policy</TrufoLink> outlines how we handle your data.</Box>
                    <Box>This Agreement is entered into by Trufo Inc. and the entity or person agreeing to these terms (the "Customer," “You” or “Your”) and governs the Customer's access to and use of the Services.</Box>
                    <Box>This Agreement is effective when the Customer is presented with this Agreement and proceeds to use the Services or to receive or distribute Assets (the "Effective Date"). These terms in the Agreement may be updated and presented again to the Customer from time to time. Continued use of the Services constitutes acceptance of the updated terms. Your access and use of the Services will be subject to the current version of the Terms of Service, rules and guidelines posted on Trufo.ai at the time of such use. Please regularly check the "Terms of Service" on Trufo.ai to view the then-current Terms. If You do not agree to the Terms of Service, please stop using the Services. </Box>
                    <Box>Other documents referenced here also govern the Customer’s use of the Services.</Box>
                    <Box height='20px' />

                    <Box fontWeight='bold'>1. Service Availability and Quality</Box>
                    <Box>We are constantly improving the Services. The Services are subject to modification and change, including but not limited to the watermarks available, the algorithms used to generate the Assets, and features available to the Customer. No guarantees are made with respect to the Services’ quality, stability, uptime or reliability. Please do not create any dependencies on any attributes of the Services or the Assets. We will not be liable to You or to any third party, including, but not limited to, Your downstream customers, for any harm caused by Your dependency on the Services.</Box>
                    <Box>Both the Services and the Assets are provided to the Customer on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied, including, without limitation, any warranties or conditions of TITLE, NON-INFRINGEMENT, MERCHANTABILITY, or FITNESS FOR A PARTICULAR PURPOSE. Trufo does not represent or warrant that the Services are fully accurate, complete, reliable, current or error-free. You are solely responsible for determining the appropriateness of using or redistributing the Assets and assume any risks associated with use of the Services. </Box>
                    <Box>Trufo reserves the right to restrict or remove content that violates this Agreement, and reserves the right to suspend, terminate and/or ban Your access to the Services at any time, and for any reason. </Box>
                    <Box>You may not use the Services for purposes of developing or offering competitive products or services. You may not attempt to interfere with, disrupt, disable, impair, or destroy the Services or the Assets. You may not upload, transmit, store, or make available any Assets or code that contains any viruses, malicious code, malware, or any components designed to harm or limit the functionality of the Services. You may not attempt to reverse engineer, decompile or discover the source code or underlying components of the Services or the Assets, or copy, modify, host, stream, sublicense, or resell the Services or the Assets You may not use automated tools to access, interact with, or generate Assets through the Services, or to interfere with or disrupt the Services, including circumventing any rate limits or restrictions or bypassing any protective measures or safety mitigations we put on our Services. You may not resell or redistribute the Services or access to the Services. Only one user may use the Services per registered account. Each user of the Services may only have one account.  You may not create any accounts for the purpose of violating the Agreement, including, but not limited to, creating fake accounts, or for circumventing account termination; enable or allow others to use the Services using your account information; access or attempt to access the Services by any means other than the interface Trufo provides or authorizes; or circumvent any access or use restrictions put into place to prevent certain uses of the Services. </Box>
                    <Box>You may not use the Service to try to misappropriate the intellectual property of others or otherwise violate the intellectual property rights of others, including copyright, patent, trademark rights, unfair competition, right of privacy, right of publicity, or any other proprietary rights. Doing so may subject you to penalties including legal action or a permanent ban from the Service.</Box>
                    <Box>We reserve the right to investigate complaints or reported or discovered violations of our Terms of Service or any laws, and to take any action we deem appropriate, including, but not limited to, reporting any suspected unlawful activity to law enforcement officials, regulators, or other third parties and disclosing any information necessary or appropriate to such persons or entities relating to user profiles, e-mail addresses, usage history, posted materials, IP addresses and traffic information.</Box>
                    <Box height='20px' />

                    <Box fontWeight='bold'>2. Your Information</Box>
                    <Box>By using the Services, You may provide Trufo with personal information like Your email address, user name, billing information, and images that You upload to the Service. Our Privacy Policy can be found at <TrufoLink href={PRIVACY_POLICY_ROUTE} trufoVariant='white'>https://trufo.ai/privacy-policy</TrufoLink>.</Box>
                    <Box height='20px' />

                    <Box fontWeight='bold'>3. Content Rights</Box>
                    <Box>You own all Assets You create with the Services to the fullest extent possible under applicable law. There are some exceptions:</Box>
                    <Box>Your ownership is subject to any obligations imposed by this Agreement and the rights of any third-parties. If you watermark the images of others, those images remain owned by the original creators. Please consult Your own lawyer if You want more information about the state of current intellectual property law in Your jurisdiction.</Box>
                    <Box>No Transfer of Rights. By using the Services, no transfer of rights in the Asset is effected.</Box>
                    <Box height='20px' />

                    <Box fontWeight='bold'>4. Rate Limiting</Box>
                    <Box>We reserve the right to rate limit You to prevent quality decay or interruptions to other customers.</Box>
                    <Box height='20px' />

                    <Box fontWeight='bold'>5. Payment and Billing</Box>
                    <Box>You are free to cancel Your account at any time, which will cause any Assets to be permanently deleted. We also reserve the right to suspend or terminate Your access to the Service for any reason, including for inappropriate use of the Service. Any violation of Terms of Service is a breach of this Agreement. </Box>
                    <Box height='20px' />

                    <Box fontWeight='bold'>6. Limitation of Liability and Indemnity</Box>
                    <Box>We provide the Services as is, and we make no promises or guarantees about it.</Box>
                    <Box>You understand and agree that we will not be liable to You or any third party for any loss of profits, use, goodwill, or data, or for any incidental, indirect, special, consequential or exemplary damages, however they arise.</Box>
                    <Box height='20px' />

                    <Box fontWeight='bold'>7. Miscellaneous</Box>
                    <Box>Force Majeure. Neither party will be liable for failure or delay in performance to the extent caused by circumstances beyond its reasonable control, including acts of God, natural disasters, terrorism, riots, or war.</Box>
                    <Box>No Agency. This Agreement does not create any agency, partnership, or joint venture between the parties.</Box>
                    <Box>Severability. If any part of this Agreement is invalid, illegal, void, or unenforceable, the rest of the Agreement will remain in effect.</Box>
                    <Box>No Third-Party Beneficiaries. This Agreement does not confer any benefits on any third party unless it expressly states that it does.</Box>
                    <Box>Governing Law. These Terms shall be governed by the laws of the State of Delaware, USA, without reference to conflict of law rules or principles (whether of this or any other jurisdiction) that would cause the application of the laws of any other jurisdiction. Any dispute between the parties that is not subject to arbitration, as well as any appeals and enforcement of arbitration awards, will be resolved in the state or federal courts of New York and the United States, respectively, except that Trufo, in its discretion, may also bring a proceeding for payment of fees in any court having jurisdiction over Customer.</Box>
                    <Box height='20px' />
                </Box>
            </Box >
        </>
    );
}